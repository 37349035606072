<template>
  <div>
    <el-table
      class="table-normal animate__animated animate__fadeIn animate__slow w-100"
      :data="data"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
      </template>
      <el-table-column
        width="55"
        v-if="checkPermissionMore('transfer') && page != 'addAdmin'"
      >
        <template slot="header" slot-scope="scope">
          <div class="is-flex ai-center">
            <el-checkbox
              v-model="checkAll"
              class="mg-0 mg-r-7"
              @change="handleSelectionChange"
              :indeterminate="isIndeterminateNow"
            ></el-checkbox>
            <el-dropdown trigger="click" placement="bottom-start">
              <span class="el-dropdown-link">
                <i class="fas fa-angle-down color-ff font-18"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="pd-x-0">
                  <div class="pd-x-4" @click="handleSelectionChange(`all`)">
                    เลือกทั้งหมด
                  </div>
                </el-dropdown-item>
                <el-dropdown-item class="pd-x-0">
                  <div class="pd-x-4" @click="handleSelectionChange(true)">
                    เลือกเฉพาะหน้านี้
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
        <template slot-scope="scope">
          <el-checkbox
            class="mg-0"
            v-model="scope.row.checkbox"
            @change="clickCheckbox(scope.$index, scope.row.id)"
            :disabled="
              scope.row.empEmail == 'yuwareek@exim.go.th' ||
              scope.row.empEmail == 'pichetd@exim.go.th' ||
              scope.row.empEmail == 'chattipong@gmail.com'
                ? true
                : false
            "
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        label="สิทธิ์ผู้ใช้งาน"
        prop="role"
        min-width="150px"
        v-if="page != 'addAdmin'"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.role != null">{{ scope.row.role }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="ชื่อ" prop="empFname" min-width="150px">
      </el-table-column>
      <el-table-column label="นามสกุล" prop="empLname" min-width="150px">
      </el-table-column>
      <el-table-column label="อีเมล" prop="empEmail" min-width="170px">
      </el-table-column>
      <el-table-column label="ฝ่าย" prop="department" min-width="150px">
      </el-table-column>
      <el-table-column label="ส่วน" prop="division" min-width="150px">
      </el-table-column>
      <el-table-column label="ตำแหน่ง" prop="position" min-width="170px">
      </el-table-column>
      <el-table-column label="สถานะใน ธสน." width="80px">
        <template slot-scope="scope">
          <span v-if="scope.row.empStatus == 'Active'" class="color-light-green"
            >Active</span
          >
          <span v-else class="color-9c">InActive</span>
        </template>
      </el-table-column>
      <el-table-column
        label="สถานะใน CFOE"
        width="115px"
        v-if="page == 'addAdmin'"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1" class="color-light-green"
            >ใช้งาน</span
          >
          <span v-else class="color-9c">ละเว้น</span>
        </template>
      </el-table-column>
      <el-table-column
        label="สิทธิ์ผู้ใช้งาน"
        prop="role"
        min-width="150px"
        v-if="page == 'addAdmin'"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.role != null">{{ scope.row.role }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        label="สิทธิ์การเข้าถึง"
        width="115px"
        prop="accessRole"
        v-if="page != 'addAdmin'"
      >
      </el-table-column>
      <el-table-column
        label="สถานะใน CFOE"
        width="115px"
        v-if="checkPermissionMore(7, 'view') && page != 'addAdmin'"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1" class="color-light-green"
            >ใช้งาน</span
          >
          <span v-else class="color-9c">ละเว้น</span>
        </template>
      </el-table-column>
      <el-table-column
        label="แก้ไขสิทธิ์การเข้าถึง"
        min-width="80px"
        v-if="checkPermissionMore(5) && page != 'addAdmin'"
      >
        <template slot-scope="scope">
          <el-button
            class="btn-color2 mg-x-auto"
            :class="
              scope.row.empEmail == 'pichetd@exim.go.th' ||
              scope.row.empEmail == 'yuwareek@exim.go.th'
                ? 'cursor-none disabled'
                : ''
            "
            @click="openModal(scope.row.id)"
          >
            <i class="fas fa-edit color-ff"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      user: (state) => state.user,
      permissionNow: (state) => state.permissionNow,
    }),
  },
  watch: {
    data() {
      this.setData();
      if (this.page != "addAdmin") {
        this.returnIndeterminate();
      }
    },
  },
  props: [
    "data",
    "page",
    "statusCheck",
    "loading",
    "lengthMulti",
    "checkSelect",
    "totalNow",
  ],
  data() {
    return {
      checkAll: false,
      multipleSelectionId: [],
      isIndeterminate: false,
      isIndeterminateNow: false,
    };
  },
  methods: {
    returnIndeterminate() {
      if (this.checkSelect.length > 0) {
        if (this.checkSelect.length == this.data.length) {
          let ifCheckbox = this.data.find((e) => e.checkbox == false);
          if (typeof ifCheckbox == "undefined") {
            this.checkAll = true;
            this.isIndeterminateNow = false;
          } else {
            this.checkAll = false;
            this.isIndeterminateNow = true;
          }
        } else {
          this.isIndeterminateNow = true;
        }
      } else {
        this.isIndeterminateNow = false;
      }
    },
    setData() {
      this.data = this.data;
      if (this.statusCheck == "all") {
        this.handleSelectionChange("all");
      } else {
        if (typeof this.checkSelect != "undefined") {
          this.checkSelect.forEach((row) => {
            this.data.forEach((rowNow) => {
              if (row.id == rowNow.id || row.empId == rowNow.id) {
                rowNow.checkbox = true;
              }
            });
          });
        }
        this.checkAll = false;
      }
    },
    openModal(val) {
      this.$emit("openSetting", val);
    },
    handleSelectionChange(val) {
      this.multipleSelectionId = [];
      if (val == true || val == "all") {
        this.isIndeterminate = false;
        this.checkAll = true;
        this.data.forEach((e) => {
          e.checkbox = true;
          this.multipleSelectionId.push({
            empId: e.id,
            status: e.status,
            role: e.role,
          });
        });
      } else {
        this.checkAll = false;
        this.data.forEach((e) => {
          e.checkbox = false;
        });
        this.multipleSelectionId = [];
      }
      if (val == "all") {
        this.$emit("exportBy", "all");
      } else {
        this.$emit("exportBy", "");
        this.$emit("exportId", this.multipleSelectionId, "thisPage");
      }
    },
    async clickCheckbox(val, id) {
      let arr = this.data.filter((e) => e.checkbox == false);
      if (arr == 0) {
        this.isIndeterminate = false;
        this.checkAll = true;
      } else {
        this.isIndeterminate = true;
        this.checkAll = false;
      }
      let whareId = this.data.filter((e) => e.id == id);
      let setDataNew = {
        empId: whareId[0].id,
        status: whareId[0].status,
        role: whareId[0].role,
      };
      this.$emit("exportBy", "");
      this.$emit("exportId", setDataNew);
      this.returnIndeterminate();
    },
    checkPermissionMore(valId, namePage) {
      if (valId == "transfer") {
        let ifPermissionRole = this.permissionNow.some(
          (a) => a.pemNum == 6 && a.status == 2
        );
        let ifPermissionStatusCFOE = this.permissionNow.some(
          (a) => a.pemNum == 7 && a.status == 2
        );
        if (ifPermissionRole === true || ifPermissionStatusCFOE === true)
          return true;
      } else {
        let ifPermission = null;
        if (namePage == "view") {
          ifPermission = this.permissionNow.some(
            (e) => e.pemNum == valId && e.status != 3
          );
        } else {
          ifPermission = this.permissionNow.some(
            (e) => e.pemNum == valId && e.status == 2
          );
        }
        if (ifPermission === false) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
};
</script>